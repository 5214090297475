import Script from "next/script"
import getConfig from "next/config"

export function PerimeterXScript() {
  const NEXT_PUBLIC_PERIMETERX_ID = process.env.NEXT_PUBLIC_PERIMETERX_ID
    if (!NEXT_PUBLIC_PERIMETERX_ID) {
        return null
    }

    return (
        <Script
            id="PerimeterXScript"
            dangerouslySetInnerHTML={{
                __html: `
(function(){
    window._pxAppId = 'PX${NEXT_PUBLIC_PERIMETERX_ID}';
    // Custom parameters
    // window._pxParam1 = "<param1>";
    var p = document.getElementsByTagName('script')[0],
        s = document.createElement('script');
    s.async = 1;
    s.src = '/${NEXT_PUBLIC_PERIMETERX_ID}/init.js';
    p.parentNode.insertBefore(s,p);
}());
`,
            }}
        />
    )
}