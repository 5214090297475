import { proximaNova } from '@/utils/fonts'
import { Global, css } from '@emotion/react'
import React from 'react'

interface GlobalStylesProps {
  children: React.ReactNode
}

const globalStyles = css`
  body {
    font-family: ${proximaNova}, sans-serif;
    background-color: #eaf0f6;
    -webkit-font-smoothing: antialiased;
  }

  button {
    font-family: ${proximaNova}, sans-serif;
  }
`

const GlobalStyles = ({ children }: GlobalStylesProps) => {
  return (
    <>
      <Global styles={globalStyles} />
      {children}
    </>
  )
}

export default GlobalStyles
