import localFont from '@next/font/local'

const proximaFonts = localFont({
  variable: '--font-proximanova',
  display: 'swap',
  src: [
    { path: '../../public/fonts/ProximaNova/ProximaNova-Regular.woff2', weight: '400', style: 'normal' },
    { path: '../../public/fonts/ProximaNova/ProximaNova-Bold.woff2', weight: '700', style: 'bold' },
  ],
})

const proximaCondensedFonts = localFont({
  variable: '--font-proximanova-condensed',
  display: 'swap',
  src: [{ path: '../../public/fonts/ProximaNova/ProximaNova-Condensed.woff2', weight: '400', style: 'normal' }],
})

const knockoutFonts = localFont({
  variable: '--font-knockout',
  display: 'swap',
  src: [
    { path: '../../public/fonts/Knockout/Knockout-51.ttf', weight: '51', style: 'normal' },
    { path: '../../public/fonts/Knockout/Knockout-67.ttf', weight: '67', style: 'normal' },
    { path: '../../public/fonts/Knockout/Knockout-68.ttf', weight: '68', style: 'normal' },
    { path: '../../public/fonts/Knockout/Knockout-69.ttf', weight: '69', style: 'normal' },
    { path: '../../public/fonts/Knockout/Knockout-73.ttf', weight: '73', style: 'normal' },
  ],
})

const shentoxFonts = localFont({
  variable: '--font-shentoxFont',
  display: 'swap',
  src: [{ path: '../../public/fonts/Shentox/Shentox-Bold.woff2', weight: '700', style: 'bold' }],
})

export const proximaNova = proximaFonts.style.fontFamily
export const proximaNovaCondensed = proximaCondensedFonts.style.fontFamily
export const knockout = knockoutFonts.style.fontFamily
export const shentox = shentoxFonts.style.fontFamily
