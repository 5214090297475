
export namespace SEO {
  export const SITE_NAME = 'FanDuel';
  export const FANDUEL_URL = 'https://www.fanduel.com/';
  export const FANDUEL_URL_NO_PROTOCOL = 'www.fanduel.com/';
  export const RESEARCH_PAGE_TITLE = 'Sports Betting and DFS News with Analysis';
  export const SEO_DESCRIPTION = 'Get the latest sports betting and DFS news from the experts at FanDuel Research. Join our community of sports enthusiasts and take your game to the next level.';
  export const TWITTERHANDLE = '@FanDuel';


  }

  export namespace FOOTER_URLS {
    export const FANDUEL_SUPPORT_URL = 'https://www.fanduel.com/support';
    export const FANDUEL_RG_URL = 'https://www.fanduel.com/RG';
    export const GAMBLING_HELPLINE_MA_URL = 'https://www.GamblingHelpLineMA.org';
    export const KANSAS_GAMBLING_HELP_URL = 'https://www.ksgamblinghelp.com';
    export const CCPG_URL = 'https://www.ccpg.org/chat';
    export const MD_GAMBLING_HELP_URL = 'https://www.mdgamblinghelp.org';
    export const GAMBLER_NET_URL = 'https://www.1800gambler.net';

  }

  export namespace FOOTER_LABELS {
    export const FANDUEL_SUPPORT_LABEL = 'FanDuel.com/RG';
    export const FANDUEL_RG_LABEL = 'http://FanDuel.com/RG ';
    export const GAMBLING_HELPLINE_MA_LABEL = 'GamblingHelpLineMA.org ';
    export const KANSAS_GAMBLING_HELP_LABEL = 'http://ksgamblinghelp.com';
    export const CCPG_LABEL = 'ccpg.org/chat';
    export const MD_GAMBLING_HELP_LABEL = 'www.mdgamblinghelp.org';
    export const GAMBLER_NET_LABEL = 'www.1800gambler.net';
  }

export namespace MAP_COLORS {
  export const AVAILABLE_FILL_COLOR = '#004ea3';
  export const DEFAULT_FILL_COLOR = '#c6d3e1';
  export const STROKE_COLOR = '#fff';
}
